import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading() {
    return (
      <div
        style={{
          backgroundColor: "white",
          color: "transparent",
          height: "100vh",
          width: "100vw",
          position: "relative",
          top: "0",
          left: "0",
        }}
      >
        <h1>Contact us</h1>
      </div>
    );
  },
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading() {
    return null;
  },
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading() {
    return null;
  },
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading() {
    return null;
  },
});

export default () => {
  return (
    <Layout>
      <SEO
        title={"Contact | Datum Consulting NZ"}
        description={
          "Get in touch with us and discuss about your needs with" +
          "technology & systems, cloud services, digital transformation, " +
          "strategy, performance improvement, software Development, " +
          "and enterprise support."
        }
        keywords={[
          `contact`,
          `email`,
          `contact form`,
          `get in touch`,
          `address`,
          `reach out`,
          `software development`,
          `digital transformation`,
          `software delivery`,
          `enterprise support`,
          `it consulting`,
        ]}
        pathname="contact"
      />
      <Nav />
      <div style={{ height: "10vh" }} />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
